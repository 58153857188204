import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'app',
    loadChildren: () => import('./pages/app-redirect/app-redirect.module').then( m => m.AppRedirectPageModule)
  },
  {
    path: 'c/:confirmationId',
    loadChildren: () => import('./pages/confirmation/confirmation.module').then( m => m.ConfirmationPageModule)
  },
  {
    path: 'v/:viewerId',
    loadChildren: () => import('./pages/viewer/viewer.module').then( m => m.ViewerPageModule)
  },
  {
    path: 'b/:restaurantId',
    loadChildren: () => import('./pages/booking/booking.module').then( m => m.BookingPageModule)
  },
  {
    path: 'edit/:confirmationId',
    loadChildren: () => import('./pages/edit/edit.module').then( m => m.EditPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
